// Helper to convert saved list of just toolbox ids to a full toolbox object ready to render

export function convertSavedToolbox(savedItems, allItems) {
  var returnObj = {};
  var items = [];
  var remainingItems = [];

  // First check at least one iD is valid to avoid returning an empty set if savesaved iDs somehow no longer match any items
  var someToolboxValid = savedItems.some((savedItem) => {
    let r = false;
    allItems.forEach((allItem) => {
      if (allItem.Id === savedItem.Id) {
        r = true;
      }
    });
    return r;
  });

  // console.log('Some valid:');
  // console.log(someToolboxValid);

  // Filter all items based on the saved list. This ensures content is updated and invalid ids from saved items are skipped
  if (savedItems.length > 0 && someToolboxValid) {
    items = allItems.filter((currentAllItem) => {
      let r = false;
      for (var i = 0; i < savedItems.length; i++) {
        if (savedItems[i].Id === currentAllItem.Id) {
          currentAllItem.SortOrder = savedItems[i].SortOrder; //Transfer the sort order across
          r = true;
        }
      }

      //Loop complete and it wasn't found, must go to remaining list
      if (!r) {
        remainingItems.push(currentAllItem);
      }
      return r;
    });

    items.sort(sortOrder); //Sort based on SortOrder prop
  } else {
    items = allItems;
  }

  returnObj.items = items;
  returnObj.remaining = remainingItems;
  // console.log('Items to push to return obj:');
  // console.log(items);
  // console.log('Saved list:');
  // console.log(savedItems);
  // console.log('Converted toolbox list:');
  // console.log(returnObj)

  return returnObj;
}

function sortOrder(a, b) {
  const orderA = a.SortOrder;
  const orderB = b.SortOrder;
  let comparison = 0;
  if (orderA > orderB) {
    comparison = 1;
  } else if (orderA < orderB) {
    comparison = -1;
  }
  return comparison;
}
