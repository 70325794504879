// Sidebar toolbox
// No modal logic as it's driven by Bootstrap

import { debounce, fadeSmoother } from "../../helpers/helpers";

import { convertSavedToolbox } from "./convertSavedToolbox";

export class SidebarToolbox {
  constructor(toolboxUserState, allItems, strToolboxKey, $el) {
    //Data
    this.savedItems = toolboxUserState.ToolboxItems;
    this.allItems = allItems;
    this.strToolboxKey = strToolboxKey;

    //Element references
    this.$el = $el;
    this.$loader = this.$el.find(".loader-2");
    this.$btnMore = this.$el.find(".mp-menu_link--more");
    this.$modal = $(this.$btnMore.data("target"));

    this.init();
  }

  init() {
    try {
      this.render();
      this.setupModalEvents();
      window.addEventListener("userStateUpdated", this.onUserStateUpdate);
    } catch (err) {
      console.warn(
        "A sidebar toolbox list setup was called but there was a problem: " +
          err
      );
      console.log(err);
      if (this.$el.length > 0) {
        this.$el.html(
          '<p style="text-align: center;">Oops, there was a problem setting up your toolbox. Please try again later.</p>'
        );
      }
    }
  }

  setupModalEvents() {
    this.$btnMore.on("click", this.handleModal);

    //Multiple ways to close modal so remove class on event
    $(".modal").on("hide.bs.modal", (ev) => {
      this.$btnMore.removeClass("-active");
    });

    //Debounce resize listener
    var self = this;
    var onResize = debounce(function () {
      self.debouncedResize();
    }, 30);
    window.addEventListener("resize", onResize);
  }

  handleModal = (ev) => {
    if ($("body").hasClass("modal-open")) {
      this.$modal.modal("hide");
    } else {
      this.$btnMore.addClass("-active");

      //Scroll to put the 'more' button centered on the page
      const pos = this.$btnMore.offset().top - window.innerHeight / 2;
      $("html, body").animate(
        {
          scrollTop: pos,
        },
        600,
        () => {
          this.$modal
            .find(".modal-dialog")
            .width($(".content-inner-main").width());
          this.$modal.modal("show");
        }
      );
    }
  };

  debouncedResize = (ev) => {
    this.$modal.find(".modal-dialog").width($(".content-inner-main").width());
  };

  onUserStateUpdate = (ev) => {
    // Called via event listener, updates content, re-renders

    setTimeout(() => {
      var savedUserState = JSON.parse(sessionStorage.getItem("userState"));
      try {
        this.savedItems = savedUserState[this.strToolboxKey].ToolboxItems;
        this.render();
      } catch (err) {
        console.warn("A toolbox update error occured: " + err);
        console.log(err);
        if (this.$el.length > 0) {
          this.$el.html(
            '<p style="text-align: center;">Oops, there was a problem setting up your toolbox. Please try again later.</p>'
          );
        }
      }
    }, 800);
  };

  getSidebarItemMarkup(item) {
    const target =
      item.PageUrl.indexOf("/systems/") > 0 ? 'target="_blank"' : "";

    return `
            <li>
                <a href="${item.PageUrl}" ${target}>
                    <div class="mp-menu__link-icon">
                        <img class="dfes-svg-icon" src="/assets/static/icons/${item.IconClass}.svg" alt="" />
                    </div>
                    <div class="mp-menu__link-label">${item.Name}</div>
                </a>
            </li>
        `;
  }

  render() {
    const itemsSeparated = convertSavedToolbox(this.savedItems, this.allItems);
    const items = itemsSeparated.items.slice(0, 3);
    var itemMarkup = "";

    this.$el.find("ul").remove();

    //Main list
    itemMarkup = items.map((item) => this.getSidebarItemMarkup(item));
    var $list = $("<ul>");
    $list.html(itemMarkup);

    //Update markup smoothly
    this.$loader
      .fadeOut(300)
      .promise()
      .done(() => {
        this.$el.find(".mp-menu_link--more").before($list);
        if (this.$btnMore.css("display") === "none") {
          fadeSmoother.fadeIn(this.$btnMore);
        }
      });
  }
}
